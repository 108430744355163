import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function CrmSap({ data }) {
  let i = 0
  return [
    <OverviewComponent {...data} key={i++} />,

    <section className="image white-bg" key={i++}>
      <div className="bgImage crmSap-bg">
        <img src={data.images.pictureCrmSap} alt=" application" />
      </div>
    </section>,

    <section className="white-bg solution" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="noMarginTop">{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="image white-bg" key={i++}>
      <div className="container">
        <img src={data.images.solutionMiddle} alt="crmSap application" />
      </div>
    </section>,

    <section className="white-bg solution last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="CrmSap">{data.delivered.title}</h2>
            <ul>
              {data.delivered.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
            <p>{data.delivered.text}</p>
          </div>
        </div>
      </div>
    </section>,
  ]
}
