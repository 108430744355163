import {
  appIcon,
  Header,
  Picture,
  SolutionLast,
  SolutionMiddle,
} from "../../images/SingleCase/CrmSap"

const dataForCrm = {
  type: "project",
  imageSrc: [],
  smart: true,
  classComponent: "CrmSap",
  nextLink: "cases/cartovera/",
  prevLink: "cases/social-app/",
  data: {
    title: "CRM/SAP Integration",
    subtitle:
      "A web application with a high level of integration with SAP Business One that allows automated booking of IP-calling or any other products.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "The customer is an international telecommunication company that develops Unified Communications solutions and VoIP products. The company with the headquarters in Italy operates in Italy, France, Germany, Eastern Europe and rapidly growing worldwide.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "CRM/SAP Integration has always specialized in unified communications development and offered its partnership services through the corporate portal since 2009. In 2013 the need for more forecastable growth became apparent. In order to identify what should be the focus, we set a continuous improvement process with the customer.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "ExtJS",
            "MySQL",
            "MSSQL",
            "Memcached",
            "SOAP",
            "Sphinx",
            "PowerDNS",
            "OpenVPN",
            "SAP B1 WS",
            "SAP DI Service",
            "AWS EC2",
            "OTRS",
            "Google APIs",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 3,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "2013 - ongoing support",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The alternatives included investing more into hardware to compete with Cisco grade companies, extending supported hardware compatibility or introducing additional B2C services.",
        "However, having run through problem identification process, we found out the partners were looking for simplified experience with the platform as well as more intuitive interfaces they were used to in everyday life.",
        "It became clear that all the alternatives were addressing partners' UX - so it became the top priority that promised increased platform traffic. Additionally, to reinforce the traffic, the Customer decided to expand its hardware compatibility to lower the entry barriers for partners.",
      ],
      textLast: [
        "It became clear that all the alternatives were addressing partners' UX - so it became the top priority that promised increased platform traffic.",
        "Additionally, to reinforce the traffic, the Customer decided to expand its hardware compatibility to lower the entry barriers for partners.",
      ],
    },
    delivered: {
      title: "Value delivered",
      text:
        "These improvements secured stable CRM/SAP Integration business growth and made solid collaboration with OSSystem which lasts already for more than five years.",
      list: [
        "SAP Business One integration",
        "Partner Contract templates for end users",
        "Remote hardware management",
        "Automated deployment of new client instances in the Cloud ",
        "E-Commerce cart with order tracking and related logistics",
        "Flexible price list and invoice printing (xls, pdf)",
        "Amazon integration",
        "Open-source Ticket Request System integration",
      ],
    },
    images: {
      bgTop: Header,
      iconLogo: appIcon,
      pictureCrmSap: Picture,
      solutionMiddle: SolutionMiddle,
      solutionLast: SolutionLast,
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/2cents",
      id: "enjoy",
      title: "2cents",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/crm-sap-integration",
}

export default dataForCrm
