import React from 'react';

import './CrmSapHeader.scss';

export default function CrmSapHeader({ data }) {

  const { images, subtitle } = data;

  return (
    <div className='crmsap-header-container' style={{ backgroundImage: `url(${images.bgTop})` }}>
      <div className='crmsap-header-wrapper'>
        <div className='crmsap-titleBlock'>
          <img src={images.iconLogo} alt='Logotype' className='crmsap-title' />
          <h1 className='crmsap-subheader'>{subtitle}</h1>
        </div>
      </div>
    </div>
  );
};
